import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isShowDetail:false,
    columnList:[],
  },
  mutations: {
    changeShowDetail(state,val){
      console.log("changeShowDetail",val);
      state.isShowDetail = val;
    },
    setColumnList(state,val){
      state.columnList = val;
    }
  },
  actions: {
  },
  modules: {
  }
})
