<template>
  <div>
    <!-- 顶部 -->
    <div class="top">
      <div class="top-center">
        <div class="top-left lf">
          <ul class="languages-en">
            <li>
              <img
                src="../assets/img/smallIcon.png"
                style="
                  margin-right: 0.20833vw;
                  margin-top: 0.15625vw;
                  height: 0.83333vw;
                  width: 0.83333vw;
                "
              />
              <span style="color: #fff;cursor: pointer" @click="toggleLang('zh')">{{
                $t("languageZH")
              }}</span>
            </li>
            <li>|</li>
            <li class="xl">
              <span @click="toggleLang('en')" style="cursor: pointer">{{ $t("languageEN") }}</span>
              <img src="../assets/img/arrow.png" height="4" width="8" style="margin-left: 0.20833vw;"/>
            </li>
            <div class="languages-container">
              <div class="languages">
                <div v-for="(data, index) in languageList" :key="data.columnId" @click="go(data, index)">
                  {{data.name}}
                </div>
              </div>
            </div>

          </ul>
        </div>
        <div class="top-left rt">
          <ul class="top-nav">
            <li @click="dialogVisible = true">{{ $t("numEW") }}</li>
            <!-- <li @click="dialogVisible = true">{{ $t("IntranetAccess") }}</li>
            <li>{{ $t("admissionInformation") }}</li>
            <li>
              <a href="http://lib.bisu.edu.cn/" target="_blank">{{
                $t("bookResources")
              }}</a>
            </li> -->
            <li>
              <a href="mailto:president@bisu.edu.cn" target="_blank">{{
                $t("pr_mailbox")
              }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 导航 -->
    <div :class="flag ? 'nav' : 'navRed'">
      <div class="nav-logo">
        <a :href="pathname">
          <img src="../assets/img/logo-new.png" class="pc_logo" v-if="flag" />
          <img
            v-if="!flag"
            src="../assets/img/redLogo-new.png"
            class="mobile_logo"
          />
        </a>
      </div>
      <div class="nav-rt">
        <ul class="link">
          <li
            v-for="(item, index) in navList"
            :key="index"
            @mouseover="show(index)"
            @mouseleave="hide(index)"
          >
            <div v-if="item.href">
              <a
                :href="item.href"
                target="_blank"
                style="width: 7.8125vw"
                class="ellipsis"
                >{{ item.name }}</a
              >
            </div>
            <router-link
              v-else
              style="width: 7.8125vw"
              class="ellipsis"
              :to="item.link"
              :class="
                index == activeIdx && item.children.length > 0 ? 'hover' : ''
              "
              >{{ item.name }}</router-link
            >
            <div
              v-if="item.children.length > 0"
              :class="index == activeIdx ? 'show link2' : 'hide link2'"
              :style="{ left: -400 - index * 50 + 'px' }"
            >
              <div class="lf">
                <div class="pic">
<!--                  <img src="../assets/img/banner.jpg" alt="" />-->
                  <img v-if="item.img" :src="item.img" alt="" />
                </div>
              </div>
              <div class="rt">
                <span v-for="(citem, cindex) in item.children" :key="cindex">
                  <a
                    v-if="citem.link"
                    :href="citem.link"
                    target="_blank"
                    style="width: 7.8125vw"
                    :class="citem.name === '国际教育学院(国际培训学院)' ? '':'ellipsis'"
                    >{{ citem.name }}</a
                  >
                  <a
                    v-else
                    class="ellipsis"
                    @click="jumpNavlist(item.link, cindex)"
                    style="cursor: pointer; width: 7.8125vw"
                    >{{ citem.name }}</a
                  >
                </span>
                <!-- <a href="/">领导视察</a>
                <a href="/">知名学者</a>
                <a href="/">现任领导</a>
                <a href="/">历任领导</a>
                <a href="/">组织机构</a>
                <a href="/">校史图志</a>
                <a href="/">信息公开</a> -->
              </div>
            </div>
          </li>
          <!-- <li>
            <a href="/" class="ha">学院设置</a>
          </li>
          <li>
            <a href="/" class="ha">师资队伍</a>
          </li>
          <li>
            <a href="/" class="ha">人才培养</a>
          </li>
          <li>
            <a href="/" class="ha">科学研究</a>
          </li>
          <li>
            <a href="/" class="ha">合作交流</a>
          </li>
          <li>
            <a href="/" class="ha">招生就业</a>
          </li> -->
        </ul>
        <div class="search rt" @click="goToSearch">
          <a class="search-search">
            <img
              src="../assets/img/search.png"
              class="top_search"
              v-if="flag"
            />
            <img
              v-if="!flag"
              src="../assets/img/redSearch.png"
              class="scroll_search"
            />
          </a>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <el-dialog
      :title="$t('inform')"
      :visible.sync="dialogVisible"
      width="40%"
      center
      top="25vh"
    >
      <div class="notice_ctx">
        {{ $t("inform_1") }}
      </div>
      <div class="notice_ctx">
        1、{{ $t("inform_2") }}：
        <a href="https://web.bisu.edu.cn" target="_blank"
          >https://web.bisu.edu.cn</a
        >{{ $t("inform_3")
        }}<a href="https://vpn.bisu.edu.cn" target="_blank"
          >https://vpn.bisu.edu.cn</a
        >
        {{ $t("inform_4") }}
      </div>
      <div class="notice_ctx">
        2、{{ $t("inform_5") }}：<a href="http://i.bisu.edu.cn" target="_blank"
          >http://i.bisu.edu.cn</a
        >
      </div>
      <div class="hint-in3a" @click="closeDialog">{{ $t("inform_6") }}</div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  inject: ["reload"],
  data() {
    return {
      pathname: "",
      treelist: [],
      flag: true,
      activeIdx: null,
      scroll: "",
      dialogVisible: false,
      navList: [
        {
          id: "1",
          name: this.$t("schoolInfor"),
          link: "/xxDetail",
          children: [
            { id: "11", name: this.$t("schoolInfor_1"), tab: "xxjj" },
            { id: "12", name: this.$t("schoolInfor_2") },
            { id: "13", name: this.$t("schoolInfor_3") },
            { id: "14", name: this.$t("schoolInfor_4") },
            // { id: "15", name: this.$t("schoolInfor_5") },
            { id: "16", name: this.$t("schoolInfor_6") },
            {
              id: "17",
              name: this.$t("schoolInfor_7"),
            },
            { id: "18", name: this.$t("schoolInfor_8") },
          ],
          img: require('./../assets/img/header/school-overview.jpeg')
        },
        {
          id: "2",
          name: this.$t("schoolInfor_9"),
          link: "/schoolSet",
          children: [],
        },
        {
          id: "3",
          name: this.$t("teachingStaff"),
          link: "/teacherTeam?id=0",
          children: [
            { id: "31", name: this.$t("teachingStaff_1") },
            { id: "32", name: this.$t("teachingStaff_2") },
            { id: "33", name: this.$t("teachingStaff_3") },
            { id: "34", name: this.$t("teachingStaff_4") },
            { id: "35", name: this.$t("teachingStaff_5") },
            {
              id: "36",
              name: this.$t("teachingStaff_6"),
              link: "http://shide.bisu.edu.cn/",
            },
            {
              id: "37",
              name: this.$t("teachingStaff_7"),
              link: "http://cfd.bisu.edu.cn/",
            },
          ],
          img: require('./../assets/img/header/teachers.jpeg')
        },
        {
          id: "4",
          name: this.$t("talentCultvation"),
          link: "/personTrain",
          children: [
            {
              id: "41",
              name: this.$t("talentCultvation_1"),
              link: "http://jwcweb.bisu.edu.cn/",
            },
            {
              id: "42",
              name: this.$t("talentCultvation_2"),
              link: "http://yjs.bisu.edu.cn/",
            },
            {
              id: "43",
              name: this.$t("talentCultvation_3"),
              link: "http://phd.bisu.edu.cn/",
            },
            // {
            //   id: "44",
            //   name: this.$t("talentCultvation_4"),
            //   link: "http://admission.bisu.edu.cn/",
            // },
            { id: "45", name: this.$t("talentCultvation_5") },
            { id: "46", name: this.$t("talentCultvation_6") },
            {
              id: "47",
              name: this.$t("talentCultvation_7"),
              link: "http://mta.bisu.edu.cn/",
            },
            {
              id: "48",
              name: this.$t("talentCultvation_8"),
              link: "http://mib.bisu.edu.cn/",
            },
            {
              id: "49",
              name: this.$t("talentCultvation_9"),
              link: "http://mpacc.bisu.edu.cn/",
            },
          ],
          img: require('./../assets/img/header/personnel.jpeg')
        },
        {
          id: "5",
          name: this.$t("scientificResearch"),
          link: "/research",
          children: [
            { id: "51", name: this.$t("scientificResearch_1") },
            { id: "52", name: this.$t("scientificResearch_2") },
            {
              id: "53",
              name: this.$t("scientificResearch_3"),
              link: "https://journal.bisu.edu.cn/",
            },
            { id: "54", name: this.$t("scientificResearch_4") },
            { id: "55", name: this.$t("scientificResearch_5") },
            { id: "56", name: this.$t("scientificResearch_6") },
            { id: "57", name: this.$t("scientificResearch_7") },
          ],
          img: require('./../assets/img/header/science.jpeg')
        },
        {
          id: "6",
          name: this.$t("cooperationExchange"),
          link: "/cooperation",
          children: [
            { id: "61", name: this.$t("cooperationExchange_1") },
            {
              id: "62",
              name: this.$t("cooperationExchange_2"),
              link: "http://hezuo.bisu.edu.cn/",
            },
            {
              id: "63",
              name: this.$t("cooperationExchange_3"),
              link: "http://kzxy.bisu.edu.cn/",
            },
          ],
          img: require('./../assets/img/header/cooperation.jpeg')
        },
        {
          id: "7",
          name: this.$t("recruitStudent"),
          link: "",
          children: [
            {
              id: "71",
              name: this.$t("recruitStudent_1"),
              link: "https://bkzs.bisu.edu.cn/",
            },
            {
              id: "72",
              name: this.$t("recruitStudent_2"),
              link: "https://yz.bisu.edu.cn/",
            },
            {
              id: "75",
              name: this.$t("recruitStudent_55"),
              link: "http://admission.bisu.edu.cn/",
            },
            {
              id: "73",
              name: this.$t("recruitStudent_3"),
              link: "http://jiuye.bisu.edu.cn/",
            },
            {
              id: "74",
              name: this.$t("recruitStudent_4"),
              link: "http://jyzx.bisu.edu.cn/",
            },
          ],
          img: require('./../assets/img/header/enrollment.jpeg')
        },
        {
          id: "8",
          name: this.$t("bookResources1"),
          link: "http://lib.bisu.edu.cn/",
          href: "http://lib.bisu.edu.cn/",
          children: [],
        },
      ],
      languageList: [
        {
          name: '조선어',
          columnId: '6670ec0e60b2c61adea115c6'
        },
        {
          name: 'العربية',
          columnId: '6670ec1660b2c61adea115c8'
        },
        {
          name: 'Español',
          columnId: '6670ec2160b2c61adea115ca'
        },
        {
          name: 'Français',
          columnId: '6670ec2760b2c61adea115cc'
        },
        {
          name: 'Deutsch',
          columnId: '6670ec2e60b2c61adea115ce'
        },
        {
          name: 'русский',
          columnId: '6670ec3560b2c61adea115d0'
        },
        {
          name: '日本語',
          columnId: '6670ec4260b2c61adea115d2'
        },
      ]
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.getTreeList();
    this.pathname = window.location.pathname;
  },
  methods: {
    goToSearch() {
      let curRoute = this.$route.path;
      console.log(curRoute, "curRoute");
      if (curRoute != "/search") {
        this.$router.push({ path: "/search" });
      }
      this.$store.commit("changeShowDetail", false);
    },
    async getTreeList() {
      const res = await this.API.basic.getColumn();
      let data = res.data;
      if (data && data.length > 0) {
        // 栏目树 当前栏目id 子栏目id data里栏目树下标 data里子栏目下标
        this.$store.commit("setColumnList", data);
        // 学校概况
        this.dataOperationalFun(
          data,
          this.$columnIdFile.xxgk,
          this.$columnIdFile.xxgkxstz,
          0,
          6
        );
        // 师资队伍
        this.dataOperationalFun(
          data,
          this.$columnIdFile.szdw,
          this.$columnIdFile.szdwsdsf,
          2,
          5
        );
        this.dataOperationalFun(
          data,
          this.$columnIdFile.szdw,
          this.$columnIdFile.szdwjsfz,
          2,
          6
        );
        // 人才培养
        this.dataOperationalFun(
          data,
          this.$columnIdFile.szdwrcpy,
          this.$columnIdFile.szdwbksjy,
          3,
          0
        );
        this.dataOperationalFun(
          data,
          this.$columnIdFile.szdwrcpy,
          this.$columnIdFile.szdwyjspy,
          3,
          1
        );
        this.dataOperationalFun(
          data,
          this.$columnIdFile.szdwrcpy,
          this.$columnIdFile.szdwlhpybs,
          3,
          2
        );
        this.dataOperationalFun(
          data,
          this.$columnIdFile.szdwrcpy,
          this.$columnIdFile.szdwlxsjy,
          3,
          3
        );
        this.dataOperationalFun(
          data,
          this.$columnIdFile.szdwrcpy,
          this.$columnIdFile.szdwmtamba,
          3,
          6
        );
        this.dataOperationalFun(
          data,
          this.$columnIdFile.szdwrcpy,
          this.$columnIdFile.szdwmib,
          3,
          7
        );
        this.dataOperationalFun(
          data,
          this.$columnIdFile.szdwrcpy,
          this.$columnIdFile.szdwnpacc,
          3,
          8
        );
        // 科学研究
        this.dataOperationalFun(
          data,
          this.$columnIdFile.kxyj,
          this.$columnIdFile.kxyjxsqk,
          4,
          2
        );
        // 合作交流
        this.dataOperationalFun(
          data,
          this.$columnIdFile.hzjl,
          this.$columnIdFile.hzjlgnjl,
          5,
          1
        );
        this.dataOperationalFun(
          data,
          this.$columnIdFile.hzjl,
          this.$columnIdFile.hzjlkzxy,
          5,
          2
        );
        // 招生就业
        this.dataOperationalFun(
          data,
          this.$columnIdFile.zsjy,
          this.$columnIdFile.zsjybkszs,
          6,
          0
        );
        this.dataOperationalFun(
          data,
          this.$columnIdFile.zsjy,
          this.$columnIdFile.zsjyyjszs,
          6,
          1
        );
        this.dataOperationalFun(
          data,
          this.$columnIdFile.zsjy,
          this.$columnIdFile.zsjyjy,
          6,
          2
        );
        this.dataOperationalFun(
          data,
          this.$columnIdFile.zsjy,
          this.$columnIdFile.zsjygjjyxy,
          6,
          3
        );
        // 图书资源
        // this.dataOperationalTSZYFun(this.$columnIdFile.tszy, 7);
      }
      // const res = await this.API.basic.getTreeData();
      // if (res.data && res.data.length > 0) {
      //   res.data.map((li, i) => {
      //     const obj = {
      //       id: `3${i}`,
      //       name: li.columnName,
      //     };
      //     this.treelist.push(obj);
      //   });
      //   this.navList[2].children.unshift(...this.treelist);
      // }
    },
    async dataOperationalFun(data, id, childId, index, childIndex) {
      let findxxgk = data.findIndex((i) => {
        return i.uuid == id;
      });
      if (findxxgk != "-1") {
        let findxxgkData = data[findxxgk].children.findIndex((i) => {
          return i.uuid == childId;
        });
        if (findxxgkData != "-1") {
          const params = {
            pageNumber: 0,
            pageSize: 1,
            columnIds: data[findxxgk].children[findxxgkData].uuid,
          };
          const resxstz = await this.API.basic.getHomeRotographList(params);
          if (resxstz.data.content) {
            const xstzUrl = await this.API.basic.getManuscriptDetails(
              resxstz.data.content[0].id
            );
            this.navList[index].children[childIndex].link = xstzUrl.data
              ? xstzUrl.data.mapProperties.erOriginLink
              : ""; // 'http://www.baidu.com'
          }
        }
      }
    },
    async dataOperationalTSZYFun(id, index) {
      const xstzUrl = await this.API.basic.getManuscriptDetails(id);
      this.navList[index].href = xstzUrl.data
        ? xstzUrl.data.mapProperties.erOriginLink
        : this.navList[index].href; // 'http://www.baidu.com'  //
    },
    jumpNavlist(link, idx) {
      // debugger;
      // item.tab;

      this.$router.push({
        path: link,
        query: {
          id: idx,
        },
      });
      this.reload();
    },
    handleScroll() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scroll > 0) {
        this.flag = false;
      } else {
        this.flag = true;
      }
    },
    toggleLang(lang) {
      //this.$i18n.locale = lang;
     // localStorage.setItem("lang", lang);
     // location.reload();
      if (lang == "en") {
        window.open("http://en.bisu.edu.cn/", "_blank");
      }
    },
    show(idx) {
      this.activeIdx = idx;
    },
    hide() {
      this.activeIdx = null;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    go(obj) {
      window.open(`${window.location.pathname}#/commonList?id=0&columnId=${obj.columnId}&showDetail=1&index=0&allDetail=1`)
    }
  },
};
</script>

<style lang="scss" scoped>
.top {
  width: 100%;
  height: 64px;
  padding: 0 80px;
  line-height: 64px;
  background: #a12916;
  color: #fff;
  position: fixed;
  top: 0;
  z-index: 999;
}
.top-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.top-center ul {
  display: flex;
  align-items: center;
}
.top-center ul li {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin: 0 5px;
}
.top-nav li {
  cursor: pointer;
}
.rt {
  ul li {
    height: 22px;
    padding: 0 10px;
    margin: 0;
    border-right: 1px solid #fff;
  }
  ul li:last-of-type {
    border-right: 0;
  }
}
.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 130px;
  padding: 0 80px;
  color: #fff;
  background: url("../assets/img/navBg.png") center top repeat-x;
  position: fixed;
  top: 64px;
  z-index: 800;
}
.navRed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 130px;
  padding: 0 80px;
  color: rgb(161, 41, 22);
  background: #fff;
  position: fixed;
  top: 64px;
  z-index: 900;
  .nav-rt ul li a {
    color: rgb(161, 41, 22);
  }
}
.nav-rt,
.nav-rt ul {
  display: flex;
  align-items: center;
}
.nav-rt ul li {
  position: relative;
  margin: 0 20px;
  width: 100px;
  text-align: center;
  font-size: 22px;
  line-height: 130px;
}
.link2 {
  padding: 40px;
  border-top: 2px solid #94070a;
  width: 900px;
  position: absolute;
  top: 100%;
  // left: -400%;
  background: #fff url("../assets/img/ernavBg.png") center bottom no-repeat;
  background-size: contain;
}
.link2 .lf {
  float: left;
  display: flex;
  width: 40%;
}
.link2 .lf .pic {
  width: 290px;
  height: 142px;
}
.link2 .lf .pic img {
  width: 100%;
  height: 100%;
}
.link2 .rt {
  float: right;
  width: 60%;
  font-size: 0;
  text-align: left;
  line-height: 200%;
}
.nav-rt .link2 .rt a {
  color: #333;
  line-height: 200%;
  width: 33.33%;
  font-size: 16px;
  margin-top: 10px;
  display: inline-block;
}
.nav-rt .link2 .rt a:hover {
  color: #94070a;
}
.link > li > a:before {
  content: "";
  border-left: 2px solid #a12916;
  height: 25px;
  transition: all 0.2s ease-out 0s;
  transform: scaleY(0);
  position: absolute;
  left: 50%;
  top: 75%;
  transform-origin: center top;
}
.link > li > a:after {
  content: "";
  border-bottom: 2px solid #a12916;
  margin-top: 5px;
  display: block;
  width: 100%;
  transition: all 0.2s ease-out 0s;
  transform: scaleX(0);
  position: absolute;
  top: 68%;
}
.hide {
  display: none;
}
.show {
  display: block;
}
.link > li > a.hover:before {
  transform: none;
}
.link > li > a.hover:after {
  transform: none;
}
::v-deep .el-dialog--center .el-dialog__body {
  padding: 0 20px 10px;
}
.notice_ctx {
  font-size: 16px;
  text-indent: 2em;
  margin: 5px 0;
  line-height: 180%;
  color: #444;
  a {
    text-decoration: underline;
    font-size: 16px;
    font-family: 微软雅黑;
    color: #a63829;
  }
}
.hint-in3a {
  font-size: 14px;
  color: #8c0000;
  line-height: 30px;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
}
.languages-container {
  position: fixed;
  top: 46px;
  left: 148px;
  .languages {
    //display: none;
    background-color: #a12916;
    color: white;
    display: flex;
    overflow: hidden;
    //width: 500px;
    justify-content: space-around;
    z-index: 1000;
    width: 0px;
    height: 0px;
    margin-top: 20px;
    &>div {
      cursor: pointer;
    }
  }
}

.languages-en {
  position: relative;
  &:hover {
    .languages-container {
      display: block;
    }
    .languages {
      display: flex;
      width: 500px;
      height: 64px;
      transition: all .5s;
    }
  }

}
</style>
