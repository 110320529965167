// zh.js
const zh = {
  el: {
    colorpicker: {
      confirm: "确定", // 确定
      clear: "清空", // 清空
    },
    datepicker: {
      now: "此刻", // 此刻
      today: "今天", // 今天
      cancel: "取消", // 取消
      clear: "清空", // 清空
      confirm: "确定", // 确定
      selectDate: "选择日期", // 选择日期
      selectTime: "选择时间", // 选择时间
      startDate: "开始日期", // 开始日期
      startTime: "开始时间", // 开始时间
      endDate: "结束日期", // 结束日期
      endTime: "结束时间", // 结束时间
      prevYear: "前一年", // 前一年
      nextYear: "后一年", // 后一年
      prevMonth: "上个月", // 上个月
      nextMonth: "下个月", // 下个月
      year: "年", // 年
      month1: "1 月", // 1 月
      month2: "2 月", // 2 月
      month3: "3 月", // 3 月
      month4: "4 月", // 4 月
      month5: "5 月", // 5 月
      month6: "6 月", // 6 月
      month7: "7 月", // 7 月
      month8: "8 月", // 8 月
      month9: "9 月", // 9 月
      month10: "10 月", // 10 月
      month11: "11 月", // 11 月
      month12: "12 月", // 12 月
      weeks: {
        sun: "日", // 日
        mon: "一", // 一
        tue: "二", // 二
        wed: "三", // 三
        thu: "四", // 四
        fri: "五", // 五
        sat: "六", // 六
      },
      months: {
        jan: "一月", // 一月
        feb: "二月", // 二月
        mar: "三月", // 三月
        apr: "四月", // 四月
        may: "五月", // 五月
        jun: "六月", // 六月
        jul: "七月", // 七月
        aug: "八月", // 八月
        sep: "九月", // 九月
        oct: "十月", // 十月
        nov: "十一月", // 十一月
        dec: "十二月", // 十二月
      },
    },
    select: {
      loading: "加载中", // 加载中
      noMatch: "无匹配数据", // 无匹配数据
      noData: "无数据", // 无数据
      placeholder: "请选择", // 请选择
    },
    cascader: {
      noMatch: "无匹配数据", // 无匹配数据
      loading: "加载中", // 加载中
      placeholder: "请选择", // 请选择
      noData: "暂无数据", // 暂无数据
    },
    pagination: {
      goto: "前往", // 前往
      pagesize: "条/页", // 条/页
      total: "共 {total} 条", // 共 {total} 条
      pageClassifier: "页", // 页
    },
    messagebox: {
      title: "提示", // 提示
      confirm: "确定", // 确定
      cancel: "取消", // 取消
      error: "输入的数据不合法!", // 输入的数据不合法!
      copySuccess: "复制成功", // 复制成功
    },
    upload: {
      deleteTip: "按 delete 键可删除", // 按 delete 键可删除
      delete: "删除", // 删除
      preview: "查看图片", // 查看图片
      continue: "继续上传", // 继续上传
    },
    table: {
      emptyText: "暂无数据", // 暂无数据
      confirmFilter: "筛选", // 筛选
      resetFilter: "重置", // 重置
      clearFilter: "全部", // 全部
      sumText: "合计", // 合计
    },
    tree: {
      emptyText: "暂无数据", // 暂无数据
    },
    transfer: {
      noMatch: "无匹配数据", // 无匹配数据
      noData: "无数据", // 无数据
      titles: ["列表 1", "列表 2"], // ['列表 1', '列表 2']
      filterPlaceholder: "请输入搜索内容", // 请输入搜索内容
      noCheckedFormat: "共 {total} 项", // 共 {total} 项
      hasCheckedFormat: "已选 {checked}/{total} 项", // 已选 {checked}/{total} 项
    },
    image: {
      error: "加载失败", // 加载失败
    },
    pageHeader: {
      title: "返回", // 返回
    },
    popconfirm: {
      confirmButtonText: "确定", // 确定
      cancelButtonText: "取消", // 取消
    },
  },
  languageZH: "中文",
  languageEN: "English ",
  numEW: "数字二外",
  IntranetAccess: "内网接入",
  admissionInformation: "招生信息",
  bookResources: "图书资源",
  pr_mailbox: "校长信箱",
  inform: "通知",
  inform_1:
    "从即日起，为加强我校业务系统安全稳定运行，请校内外用户分别通过以下方式访问数字校园。",
  inform_2: "校外用户",
  inform_3: "或者",
  inform_4:
    "(两者任选其一，两种方式的账户和密码均与校内WIFI登录的账户与密码一致)",
  inform_5: "校内用户",
  inform_6: "关闭",
  schoolInfor: "学校概况",
  schoolInfor_1: "学校简介",
  schoolInfor_2: "领导视察",
  schoolInfor_3: "知名学者",
  schoolInfor_4: "学校领导",
  schoolInfor_5: "历任领导",
  schoolInfor_6: "组织机构",
  schoolInfor_7: "校史图志",
  schoolInfor_8: "信息公开",
  schoolInfor_9: "学院设置",
  teachingStaff: "师资队伍",
  teachingStaff_1: "杰出学者",
  teachingStaff_2: "博士生导师",
  teachingStaff_3: "专家教授",
  teachingStaff_4: "外籍专家",
  teachingStaff_5: "青年英才",
  teachingStaff_6: "师德师风",
  teachingStaff_7: "教师发展",
  talentCultvation: "人才培养",
  talentCultvation_1: "本科生教育",
  talentCultvation_2: "研究生培养",
  talentCultvation_3: "联合培养博士",
  talentCultvation_4: "留学生教育",
  talentCultvation_5: "港澳台侨生",
  talentCultvation_6: "继续教育",
  talentCultvation_7: "MTA&MBA教育中心",
  talentCultvation_8: "MIB专题网站",
  talentCultvation_9: "MPAcc专题网站",
  scientificResearch: "科学研究",
  scientificResearch_1: "研究院",
  scientificResearch_2: "实验室",
  scientificResearch_3: "学术期刊",
  scientificResearch_4: "研究基地",
  scientificResearch_5: "研究中心",
  scientificResearch_6: "科研项目",
  scientificResearch_7: "科研成果",
  cooperationExchange: "合作交流",
  cooperationExchange_1: "国际交流",
  cooperationExchange_2: "国内交流",
  cooperationExchange_3: "孔子学院",
  recruitStudent: "招生就业",
  recruitStudent_1: "本科生招生",
  recruitStudent_2: "研究生招生",
  recruitStudent_3: "就业",
  recruitStudent_4: "国际教育学院(国际培训学院)",
  recruitStudent_55: "留学生招生",
  bookResources1: "图书资源",
  importantNews: "要闻速递",
  viewMore: "查看更多",
  top: "顶",
  notificationAnnoun: "通知公告",
  academicTrends: "学术动态",
  mediaEW: "媒体二外",
  schoolFocus: "校园看点",
  viewDetails: "查看详情",
  viewDetails_2: "乘风破浪 二外起航",
  viewDetails_3: "Mingde, Studious, Realistic,",
  viewDetails_4: "Competitive first",
  viewDetails_5: "传承东西文明，促进中外交流",
  viewDetails_6: "招生资讯",
  viewDetails_7: "Undergraduates",
  viewDetails_8: "本科生招生",
  viewDetails_9:
    "学校有本科专业 48 个，外语类专业 31 个。其中国家级一流专业建设点 15 个（英语、商务英语、日语、朝鲜语、法语、俄语、德语、西班牙语、葡萄牙语、阿拉伯语、波斯语、翻译、旅游管理、酒店管理、财务管理），国家级特色专业 4 个（日语、阿拉伯语、英语、旅游管理），北京市一流专业建设点 14 个（印地语、意大利语、匈牙利语、塞尔维亚语、爱沙尼亚语、会展经济与管理、市场营销、国际经济与贸易、贸易经济、金融学、国际事务与国际关系、汉语国际教育、汉语言文学、新闻学），教育部专业综合改革试点专业 1 个（日语）",
  viewDetails_10: "MORE",
  viewDetails_11: "Graduate Students",
  viewDetails_12: "研究生招生",
  viewDetails_13:
    "研究生招生包括外国语言文学、工商管理、中国语言文学、应用经济学和哲学五个",
  viewDetails_14: "International Students",
  viewDetails_15: "留学生招生",
  viewDetails_16:
    "会计学专业硕士、旅游管理硕士、国际工商管理硕士（MBA）、硕士研究生等",
  recruitStudent_5: "本科生招生",
  recruitStudent_5_2: "研究生招生",
  recruitStudent_6: "就业",
  recruitStudent_7: "招聘",
  recruitStudent_8: "校友网",
  recruitStudent_9: "教育发展基金会",
  recruitStudent_10: "校长信箱:president@bisu.edu.cn 纪检监察举报",
  recruitStudent_11: "北京第二外国语学院 版权所有 京ICP备：05067963号",
  recruitStudent_12: "文保网安备案号：110402430076",
  recruitStudent_13: "Copyright 2001-2010 B.I.S.U. all rights reserved",
  newsInformation: "NEWS",
  newsInformation_1: "新闻资讯",
  newsInformation_2: "专题",
  newsInformation_3: "新闻头条",
  noData: "暂无数据",
  relatedReading: "Related Reading",
  relatedReading_1: "相关阅读",
  relatedReading_2: "Related Reading",
  month_1: "月",
  day_1: "日",
  year_1: "年",
  source_1: "来源",
  source_2: "文字",
  source_3: "摄影",
  source_4: "编辑",
  source_5: "领导视察",
  source_6: "信息公开",
  source_7: "分享",
  source_8: "微信扫一扫：分享",
  source_9: "微信里点“发现”，扫一下",
  source_10: "二维码便可将本文分享至朋友圈。",
  successive_leaders: "历任领导",
  successive_leaders1: "历任党委书记",
  successive_leaders2: "历任校长",
  leadershipName: "张天恩",
  leadershipName1: "罗林",
  leadershipName2: "孙萍",
  leadershipName3: "唐恺",
  leadershipName4: "雷文",
  leadershipName5: "李先辉",
  leadershipName6: "洪福尔",
  leadershipName7: "刘凤魁",
  leadershipName8: "常殿元",
  leadershipName9: "段建国",
  leadershipName10: "冯培",
  leadershipName11: "李昌",
  leadershipName12: "文革期间",
  leadershipName13: "唐恺",
  leadershipName14: "张道一",
  leadershipName15: "韩克华",
  leadershipName16: "李先辉",
  leadershipName17: "常殿元",
  leadershipName18: "杜江",
  leadershipName19: "周烈",
  leadershipName20: "曹卫东",
};

export default zh;
