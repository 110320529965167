export default [
  {
    path: "/news_info",
    name: "news_info",
    component: () =>
      import("../views/tourismManagement/components/homeConter.vue"),
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    component: () => import("../views/tourismManagement/aboutUs.vue"),
  },
  {
    path: "/newsInfo",
    name: "newsInfo",
    component: () => import("../views/tourismManagement/newsInfo.vue"),
  },
  {
    path: "/teachTeam",
    name: "teachTeam",
    component: () => import("../views/tourismManagement/teachTeam.vue"),
  },
  {
    path: "/undergraduate",
    name: "undergraduate",
    component: () => import("../views/tourismManagement/teachResearch/undergraduate.vue"),
  },
  {
    path: "/postgraduate",
    name: "postgraduate",
    component: () => import("../views/tourismManagement/teachResearch/postgraduate.vue"),
  },
  {
    path: "/science",
    name: "science",
    component: () => import("../views/tourismManagement/teachResearch/science.vue"),
  },
  {
    path: "/society",
    name: "society",
    component: () => import("../views/tourismManagement/teachResearch/society.vue"),
  },
  {
    path: "/studentWork",
    name: "studentWork",
    component: () => import("../views/tourismManagement/studentWork.vue"),
  },
  {
    path: "/international",
    name: "international",
    component: () => import("../views/tourismManagement/international.vue"),
  },
  {
    path: "/recruitStudents",
    name: "recruitStudents",
    component: () => import("../views/tourismManagement/recruitStudents.vue"),
  },
  {
    path: "/partyBuilding",
    name: "partyBuilding",
    component: () => import("../views/tourismManagement/partyBuilding.vue"),
  },
  {
    path: "/noticeAnnouncement",
    name: "noticeAnnouncement",
    component: () => import("../views/tourismManagement/noticeAnnouncement.vue"),
  },
  {
    path: "/tradeUnion",
    name: "tradeUnion",
    component: () => import("../views/tourismManagement/tradeUnion.vue"),
  },
];
