export default [
  {
    path: "/nationalCulturalDevelopmentHome",
    name: "nationalCulturalDevelopmentHome",
    component: () =>
      import("../views/nationalCulturalDevelopment/components/home.vue"),
  },
  {
    path: "/researchInstitute",
    name: "researchInstitute",
    component: () =>
      import("../views/nationalCulturalDevelopment/researchInstitute.vue"),
  },
  {
    path: "/newsUpdates",
    name: "newsUpdates",
    component: () =>
      import("../views/nationalCulturalDevelopment/newsUpdates.vue"),
  },
  {
    path: "/researchTeam",
    name: "researchTeam",
    component: () =>
      import("../views/nationalCulturalDevelopment/researchTeam.vue"),
  },
  {
    path: "/highEnd",
    name: "highEnd",
    component: () =>
      import("../views/nationalCulturalDevelopment/highEnd.vue"),
  },
  {
    path: "/nationalLevel",
    name: "nationalLevel",
    component: () =>
      import("../views/nationalCulturalDevelopment/nationalLevel.vue"),
  },
  {
    path: "/provincialDepartment",
    name: "provincialDepartment",
    component: () =>
      import("../views/nationalCulturalDevelopment/provincialDepartment.vue"),
  },
  {
    path: "/departmentLevel",
    name: "departmentLevel",
    component: () =>
      import("../views/nationalCulturalDevelopment/departmentLevel.vue"),
  },
  {
    path: "/researchTasks",
    name: "researchTasks",
    component: () =>
      import("../views/nationalCulturalDevelopment/researchTasks.vue"),
  },
  {
    path: "/achievement",
    name: "achievement",
    component: () =>
      import("../views/nationalCulturalDevelopment/achievement.vue"),
  },
  {
    path: "/academicTrend",
    name: "academicTrend",
    component: () =>
      import("../views/nationalCulturalDevelopment/academicTrend.vue"),
  },
  {
    path: "/cooperate",
    name: "cooperate",
    component: () =>
      import("../views/nationalCulturalDevelopment/cooperate.vue"),
  },
  {
    path: "/noticeAndAnnouncement",
    name: "noticeAndAnnouncement",
    component: () =>
      import("../views/nationalCulturalDevelopment/noticeAndAnnouncement.vue"),
  },
  {
    path: "/mediaCoverage",
    name: "mediaCoverage",
    component: () =>
      import("../views/nationalCulturalDevelopment/mediaCoverage.vue"),
  },
];
