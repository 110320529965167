import Vue from "vue";
import VueRouter from "vue-router";
import tourism from './tourism'
import div from './dev'
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/newsList",
    name: "newsList",
    component: () => import("../views/newsList.vue"),
  },
  {
    path: "/commonList",
    name: "commonList",
    component: () => import("../views/wgList/commonList"),
  },
  {
    path: "/newsDetail",
    name: "newsDetail",
    component: () => import("../views/newsDetail.vue"),
  },
  {
    path: "/xxDetail",
    name: "xuexiaoDetail",
    component: () => import("../views/xuexiaoDetail/columnList.vue"),
  },
  {
    path: "/schoolSet",
    name: "schoolSet",
    component: () => import("../views/schoolSet.vue"),
  },
  {
    path: "/teacherTeam",
    name: "teacherTeam",
    component: () => import("../views/teacherTeam"),
  },
  {
    path: "/cooperation",
    name: "cooperation",
    component: () => import("../views/cooperation/index.vue"),
  },
  {
    path: "/research",
    name: "research",
    component: () => import("../views/research/index.vue"),
  },
  {
    path: "/personTrain",
    name: "personTrain",
    component: () => import("../views/personTrain"),
  },
  {
    path: "/gjDetail",
    name: "gjDetail",
    component: () => import("../views/gjDetail/index.vue"),
  },
  {
    path: "/ztlist",
    name: "ztlist",
    component: () => import("../views/ztList.vue"),
  },
  {
    path: "/ztDetail",
    name: "ztDetail",
    component: () => import("../views/ztDetail.vue"),
  },
  {
    path: "/search",
    name: "search",
    component: () => import("../views/search.vue"),
  },
  {
    path: "/tourismManagement",
    name: "tourismManagement",
    component: () => import("../views/tourismManagement"),
    redirect:'/news_info',
    children:[
      ...tourism
    ]
  },
  {
    path: "/nationalCulturalDevelopment",
    name: "nationalCulturalDevelopment",
    component: () => import("../views/nationalCulturalDevelopment"),
    redirect:'/nationalCulturalDevelopmentHome',
    children:[
      ...div
    ]
  },
  {
    path: "/phoneHome",
    name: "phoneHome",
    redirect: "",
    component: () => import("../views/PhoneHome.vue"),
  },
  {
    path: "/phoneList",
    name: "phoneList",
    redirect: "",
    component: () => import("../views/PhoneList.vue"),
  },
  {
    path: "/phoneDetail",
    name: "phoneDetail",
    redirect: "",
    component: () => import("../views/PhoneDetail.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  //页面滚动到顶部
  window.scrollTo(0, 0);
  next();
});

export default router;
