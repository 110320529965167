<template>
  <div>
    <!-- 链接 -->
    <div class="lj">
      <div class="w1380">
        <ul class="lj-div1">
          <li>
            <a href="https://bkzs.bisu.edu.cn" target="_blank">{{
              $t("recruitStudent_5")
            }}</a>
          </li>
          <li>
            <a href="https://yz.bisu.edu.cn" target="_blank">{{
              $t("recruitStudent_5_2")
            }}</a>
          </li>
          <li>
            <a href="http://jiuye.bisu.edu.cn/" target="_blank">{{
              $t("recruitStudent_6")
            }}</a>
          </li>
          <li>
<!--            <a href="javascript:void(0);" @click="toRecruitStudent()">{{-->
<!--              $t("recruitStudent_7")-->
<!--            }}</a>-->
            <a href="javascript:void(0);" @click="toDetail('652cf4cae4b017e9bcb0b3f0')">{{
                $t("recruitStudent_7")
              }}</a>
          </li>
          <li>
            <a href="http://bisuer.bisu.edu.cn/" target="_blank">{{
              $t("recruitStudent_8")
            }}</a>
          </li>
          <li>
            <a href="http://edf.bisu.edu.cn/" target="_blank">{{
              $t("recruitStudent_9")
            }}</a>
          </li>
        </ul>
        <ul class="lj-div2">
          <li>
            <a class="wx"
              ><img
                @mouseenter="mouseenter(1)"
                @mouseleave="mouseleave"
                src="../assets/img/weixin.png"
              />
            </a>
          </li>
          <li>
            <a class="wb"
              ><img
                @mouseenter="mouseenter(2)"
                @mouseleave="mouseleave"
                src="../assets/img/weibo.png"
              />
            </a>
          </li>
          <li>
            <a class="dy"
              ><img
                @mouseenter="mouseenter(3)"
                @mouseleave="mouseleave"
                src="../assets/img/douyin.png"
              />
            </a>
          </li>
          <div class="ew_img">
            <img v-if="ewImgIndex == 1" class="wx_img" src="./weixin.png" />
            <img v-if="ewImgIndex == 2" class="wb_img" src="./weibo.png" />
            <img v-if="ewImgIndex == 3" class="dy_img" src="./douyin.png" />
          </div>
        </ul>
      </div>
    </div>
    <!-- 版权 -->
    <div class="footer">
      <div class="w1380">
        <p>{{ $t("recruitStudent_10") }}</p>
        <p>
          {{ $t("recruitStudent_11") }}
          {{ $t("recruitStudent_12") }}
        </p>
        <p>{{ $t("recruitStudent_13") }}</p>
        <p class="auth">
          <a href=""><img src="../assets/img/copyright.png" /></a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ewImgIndex: 0,
    };
  },
  methods: {
    mouseenter(index) {
      this.ewImgIndex = index;
    },
    mouseleave() {
      this.ewImgIndex = 0;
    },
    toRecruitStudent() {
      localStorage.setItem("currentIm", "招聘");
      this.$router.push({
        path: "/newsList",
        query: {
          cid: this.$columnIdFile.zp,
        },
      });
    },
    toDetail(id) {
      const { href } = this.$router.resolve({
        path: "/commonList",
        query: {
          id: 0,
          columnId: id
        },
      })
      window.open(href, '_blank');
    }
  },
};
</script>

<style lang="scss" scoped>
.lj {
  background-color: #ad2e1b;
  height: 185px;
  .w1380 {
    display: flex;
    justify-content: space-between;
  }
}
.w1380 {
  width: 1380px;
  margin: 0 auto;
}
.lj-div1 {
  display: flex;
}
.lj-div1 li {
  width: 142px;
  height: 130px;
  font-size: 16px;
  border-left: 1px solid #bd5849;
  text-align: center;
  padding-top: 85px;
}
.lj-div1 li:last-of-type {
  border-right: 1px solid #bd5849;
}
.lj-div2 {
  display: flex;
  position: relative;
}
.ew_img {
  position: absolute;
  bottom: -180px;
  left: 26px;
  width: 180px;
  height: 180px;
  img {
    width: 100%;
  }
}
.lj-div2 li {
  width: 95px;
  text-align: center;
  padding-top: 70px;
  cursor: pointer;
  img {
    width: 48px;
    height: 48px;
  }
}
.footer {
  height: 200px;
  background-color: #a12916;
  color: #fff;
  text-align: center;
  padding-top: 30px;
  line-height: 150%;
  font-size: 14px;
  .auth {
    display: flex;
    justify-content: center;
    margin-top: 6px;
    img{
      width:68px;
      height: 68px;
    }
  }
}
</style>
