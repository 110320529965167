import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// import "amfe-flexible/index.js";

import "../src/assets/css/global.css";

import columnIdFile from './assets/json/column.json'

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// Vue.use(ElementUI);

import "../node_modules/swiper/swiper-bundle.css"; //swiper6.0样式路径
import Swiper, { Navigation, Autoplay, Thumbs } from "swiper"; //相关组件
Swiper.use([Navigation, Autoplay, Thumbs]);

import API from "./api/index";
Vue.prototype.API = API;

// import VueI18n from "vue-i18n";
// Vue.use(VueI18n);
import i18n from "./locale/i18n/i18n.js";

import moment from 'moment';
Vue.prototype.$moment = moment;
Vue.prototype.$columnIdFile = columnIdFile;

import Vant from "vant";
import "vant/lib/index.css";

Vue.use(Vant);

// 点击防抖
const throttle = {
    bind: (el, binding) => {
        let throttleTime = binding.value // 防抖时间
        if (!throttleTime) { // 用户若不设置防抖时间，则默认1s
            throttleTime = 500
        }
        let timer
        let disable = false
        el.addEventListener('click', event => {
            if (timer) {
                clearTimeout(timer)
            }
            if (!disable) { // 第一次执行(一点击触发当前事件)
                disable = true
            } else {
                event && event.stopImmediatePropagation()
            }
            timer = setTimeout(() => {
                timer = null
                disable = false
            }, throttleTime)
        }, true)
    }
}

Vue.directive('throttle', throttle);
Vue.use(ElementUI, { i18n: (key, value) => i18n.t(key, value) });
// const i18n = new VueI18n({
//     locale: "zh", //语言标识
//     messages: {
//         //配置多语言 根据自己的项目需求  这里配置了中文和英文
//         zh: require("./i18n/zh.js"), //中文
//         en: require("./i18n/en.js"), //英文
//     },
//     fallbackLocale: "zh", //没有英文的时候默认 中文语言
//     silentTranslationWarn: true, //报错时加上这个参数可以取消警告
// });

Vue.config.productionTip = false;

// //生产 pc部署文件
// const productionPcUrl = "zh";
// //生产 移动端部署文件
// const productionMobileUrl = "mobile";

new Vue({
    router,
    store,
    i18n,
    // beforeCreate() {
    //     const userAgent = navigator.userAgent;
    //     const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
    //     console.log(`当前环境: ${isMobile ? "移动端" : "桌面端"}`);
    //     if (isMobile && process.env.NODE_ENV !== "development") {
    //       // 如果是生产环境移动端访问 则重定向到移动端项目
    //       console.log(`当前环境: ${isMobile ? "移动端" : "桌面端"}`);
    //       if (location.pathname.includes(productionPcUrl)) { 
    //         const newPathname = location.pathname.replace(
    //           productionPcUrl,
    //           productionMobileUrl
    //         );
    //         // 重定移动端
    //         window.location = location.origin + newPathname + '#/phoneHome';
    //       }
    //     }
    //   },
    render: (h) => h(App),
}).$mount("#app");