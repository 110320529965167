<template>
  <div id="app">
    <!-- <Header v-if="!routeType && !isPhone()"></Header>
    <PhoneHeader v-if="isPhone()"></PhoneHeader>
    <router-view v-if="isRouterAlive" />
    <Footer v-if="!routeType && !isPhone()"></Footer>
    <PhoneFooter v-if="isPhone()"></PhoneFooter> -->
    <Header></Header>
    <router-view v-if="isRouterAlive" />
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
// import PhoneHeader from "./components/Phone/PhoneHeader.vue";
// import PhoneFooter from "./components/Phone/PhoneFooter.vue";
export default {
  components: {
    Header,
    Footer,
    // PhoneHeader,
    // PhoneFooter,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  computed: {
    routeType() {
      return this.$route?.query?.routeType == "2";
    },
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    isPhone() {
      const userAgent = navigator.userAgent;
      const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
      return isMobile;
    },
  },
};
</script>

<style lang="scss">
@import "./assets/css/common";
// ::-webkit-scrollbar {
//   position: absolute;
//   width: 6px;
//   height: 6px;
//   margin-left: -10px;
// }
// ::-webkit-scrollbar-thumb {
//   cursor: pointer;
//   background-color: #f0f1f5;
//   background-clip: content-box;
//   border-color: transparent;
//   border-style: solid;
//   border-width: 1px 2px;
//   border-radius: 7px;
// }
// ::-webkit-scrollbar-track {
//   background-color: transparent;
//   border-right: none;
//   border-left: none;
// }

img {
  max-width: 100% !important;
}
video {
  max-width: 100% !important;
}
body {
  font-family: "Noto Sans SC", "微软雅黑", "黑体" !important;
}
</style>
