import axios from "axios";
import { Notification } from "element-ui";
import router from "../router/index";

const httpService = axios.create({
    baseURL: process.env.NODE_ENV === "development" ? "/api" : "/mixmedia/api", //生产
    // baseURL: process.env.NODE_ENV === "development" ? "http://36.112.157.200:19080/api" : "https://qscloudsite.wengegroup.com/mixmedia/api", // 测试
    timeout: 50000, //请求超时时间
});

const exceptionCode = ["400", "500", ""];
//拦截请求
httpService.interceptors.request.use((config) => {
    if (config.url.includes('site/erwai/story/signatures/list') || config.url.includes('site/erwai/story/signatures/list')) {
        config.headers["Content-Type"] = "multipart/form-data"
    }
    config.headers["app_key"] = "sprint-ucm";
    config.headers["app_secret"] = "bbbbbbaaaaaaaaaaaaa";
    return config;
});

//拦截响应
httpService.interceptors.response.use(
    (res) => {
        console.log(res,res.data,"222")
        if (exceptionCode.includes(res.data.code)) {
            console.log(res.data.data);
        }
        if (res.data.errorCode == 401 && res.data.message == '未登录') {
            localStorage.removeItem('userData')
            router.push({ path: "/login" });
            return Promise.resolve(null);
        } else if (["401", "444", 401, 404].includes(res.data.errorCode)) {
            localStorage.removeItem('userData')
            Notification.closeAll();
            Notification({
                title: "提示",
                message: res.data.message,
                customClass: "hats-ui-message",
                type: "3000",
                duration: 2000,
                onClose() {},
            });
            router.push({ path: "/login" });
            return Promise.resolve("noAuth");
        }
        if (res.status == "200") {
            return Promise.resolve(res.data);
        } else {
            return Promise.resolve(null);
        }
    },
    (error) => {
        if (error.response.status === 401) {
            //token过期
            Notification({
                title: "提示",
                message: "登录过期，即将跳转登录界面！",
                customClass: "hats-ui-message",
                type: "error",
                duration: 3000,
                onClose() {},
            });
            return Promise.reject("noAuth");
        } else {
            Notification.closeAll();
            return Promise.resolve(null);
        }
    }
);

/**
 * get 请求方法
 * @param url
 * @param params
 * @returns {Promise}
 */
export function get(url, params = {}, config) {
    return new Promise((resolve, reject) => {
        httpService
            .get(url, {
                params: params,
                ...config,
            })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
}
// post请求
export function post(url, data = {}, method = "post") {
    return new Promise((resolve, reject) => {
        httpService({ method, url, data })
            .then((res) => {
                /* 成功*/
                resolve(res);
            })
            .catch((res) => {
                // 失败
                reject(res);
            });
    });
}

export function myRequest(url, data = {}) {
    let formData = new FormData();
    // for (let key in data) {
    // 	if (data.hasOwnProperty.call(key)) {
    // 		let ele = data[key]
    // 		formData.append(key, ele)
    // 	}
    // }
    // formData.append("app", "2");
    // formData.append("timeStamp", localStorage.getItem("timeStamp"));
    // formData.append("key", "lvfaweb");
    // formData.append("signatures", localStorage.getItem("signatures"));
    if (data.columnIds) {
        formData.append("columnIds", data.columnIds);
    }
    // if (data.keywords) {
    //   formData.append("keywords", data.keywords);
    // }
    formData.append("pageNumber", data.pageNumber);
    formData.append("pageSize", data.pageSize);
    return post(url, formData);
}