// en.js
const en = {
  el: {
    colorpicker: {
      confirm: "Confirm",
      clear: "Clear",
    },
    datepicker: {
      now: "Now",
      today: "Today",
      cancel: "Cancel",
      clear: "Clear",
      confirm: "Confirm",
      selectDate: "Select Date",
      selectTime: "Select Time",
      startDate: "Start Date",
      startTime: "Start Time",
      endDate: "End Date",
      endTime: "End Time",
      prevYear: "Previous Year",
      nextYear: "Next Year",
      prevMonth: "Last Month",
      nextMonth: "Next Month",
      year: "Year",
      month1: "January",
      month2: "February",
      month3: "March",
      month4: "April",
      month5: "May",
      month6: "June",
      month7: "July",
      month8: "August",
      month9: "September",
      month10: "October",
      month11: "November",
      month12: "December",
      weeks: {
        sun: "Sun",
        mon: "Mon",
        tue: "Tue",
        wed: "Wed",
        thu: "Thu",
        fri: "Fri",
        sat: "Sat",
      },
      months: {
        jan: "January",
        feb: "February",
        mar: "March",
        apr: "April",
        may: "May",
        jun: "June",
        jul: "July",
        aug: "August",
        sep: "September",
        oct: "October",
        nov: "November",
        dec: "December",
      },
    },
    select: {
      loading: "Loading",
      noMatch: "No matching data",
      noData: "No data",
      placeholder: "Select",
    },
    cascader: {
      noMatch: "No matching data",
      loading: "Loading",
      placeholder: "Select",
      noData: "No data",
    },
    pagination: {
      goto: "Go to",
      pagesize: "items/page",
      total: "Total {total} items",
      pageClassifier: "page",
    },
    messagebox: {
      title: "Message",
      confirm: "OK",
      cancel: "Cancel",
      error: "Invalid input!",
      copySuccess: "Copied successfully",
    },
    upload: {
      deleteTip: "Press delete key to remove",
      delete: "Delete",
      preview: "Preview",
      continue: "Continue uploading",
    },
    table: {
      emptyText: "No data available",
      confirmFilter: "Apply",
      resetFilter: "Reset",
      clearFilter: "All",
      sumText: "Sum",
    },
    tree: {
      emptyText: "No data available",
    },
    transfer: {
      noMatch: "No matching data",
      noData: "No data",
      titles: ["List 1", "List 2"],
      filterPlaceholder: "Enter search keyword",
      noCheckedFormat: "Total {total} items",
      hasCheckedFormat: "Checked {checked}/{total} items",
    },
    image: {
      error: "Failed to load",
    },
    pageHeader: {
      title: "Back",
    },
    popconfirm: {
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
    },
  },
  languageZH: "Chinese",
  languageEN: "English",
  numEW: "Number Second Language",
  IntranetAccess: "Intranet Access",
  admissionInformation: "Admissions Information",
  bookResources: "Book Resources",
  pr_mailbox: "Principal's Mailbox",
  inform: "Notice",
  inform_1:
    "Starting from today, in order to strengthen the security and stability of our school's business systems, please access the digital campus through the following methods.",
  inform_2: "Off-campus Users",
  inform_3: "Or",
  inform_4:
    "(Choose either one, the account and password for both methods are the same as the one used for on-campus WiFi login)",
  inform_5: "On-campus Users",
  inform_6: "Close",
  schoolInfor: "School Overview",
  schoolInfor_1: "School Profile",
  schoolInfor_2: "Leadership Visits",
  schoolInfor_3: "Prominent Scholars",
  schoolInfor_4: "Current Leadership",
  schoolInfor_5: "Past Leadership",
  schoolInfor_6: "Organizational Structure",
  schoolInfor_7: "School History & Chronicles",
  schoolInfor_8: "Information Disclosure",
  schoolInfor_9: "Academic Colleges",
  teachingStaff: "Teaching Staff",
  teachingStaff_1: "Outstanding Scholars",
  teachingStaff_2: "Doctoral Supervisors",
  teachingStaff_3: "Experts & Professors",
  teachingStaff_4: "Foreign Experts",
  teachingStaff_5: "Young Talents",
  teachingStaff_6: "Teacher Ethics",
  teachingStaff_7: "Teacher Development",
  talentCultivation: "Talent Cultivation",
  talentCultivation_1: "Undergraduate Education",
  talentCultivation_2: "Graduate Education",
  talentCultivation_3: "Jointly-Cultivated Doctorates",
  talentCultivation_4: "International Student Education",
  talentCultivation_5:
    "Hong Kong, Macao, Taiwan, and Overseas Chinese Students",
  talentCultivation_6: "Continuing Education",
  talentCultivation_7: "MTA & MBA Education Center",
  talentCultivation_8: "MIB Special Website",
  talentCultivation_9: "MPAcc Special Website",
  scientificResearch: "Scientific Research",
  scientificResearch_1: "Research Institutes",
  scientificResearch_2: "Laboratories",
  scientificResearch_3: "Academic Journals",
  scientificResearch_4: "Research Bases",
  scientificResearch_5: "Research Centers",
  scientificResearch_6: "Research Projects",
  scientificResearch_7: "Research Achievements",
  cooperationExchange: "Cooperation & Exchange",
  cooperationExchange_1: "International Cooperation",
  cooperationExchange_2: "Domestic Cooperation",
  cooperationExchange_3: "Confucius Institutes",
  recruitStudent: "Admissions & Employment",
  recruitStudent_1: "Undergraduate Admissions",
  recruitStudent_2: "Graduate Admissions",
  recruitStudent_3: "Employment",
  recruitStudent_4:
    "International Education College (International Training College)",
  bookResources1: "Book Resources",
  importantNews: "Important News Briefs",
  viewMore: "View More",
  top: "Top",
  notificationAnnoun: "Notification & Announcement",
  academicTrends: "Academic Trends",
  mediaEW: "Media of EWC",
  schoolFocus: "Campus Focus",
  viewDetails: "View Details",
  viewDetails_2: "Riding the Waves, BFSU Sets Sail",
  viewDetails_3: "Mingde, Studious, Realistic,",
  viewDetails_4: "Competitive First",
  viewDetails_5:
    "Carrying on the Civilizations of the East and West, Promoting Cultural Exchanges between China and the World",
  viewDetails_6: "Admissions Information",
  viewDetails_7: "Undergraduates",
  viewDetails_8: "Undergraduate Admissions",
  viewDetails_9:
    "Undergraduate admissions include English College, Japanese College, Arabic College, Western European Languages College, Central European Languages College, etc.",
  viewDetails_10: "MORE",
  viewDetails_11: "Graduate Students",
  viewDetails_12: "Graduate Admissions",
  viewDetails_13:
    "Graduate admissions include Foreign Language Literature, Business Administration, Chinese Language and Literature, Applied Economics, and Philosophy.",
  viewDetails_14: "International Students",
  viewDetails_15: "International Student Admissions",
  viewDetails_16:
    "Master's degree programs in Accounting, Tourism Management, International Business Administration (MBA), and more Master's degree programs.",
  recruitStudent_5: "Undergraduate Admissions",
  recruitStudent_5_2: "Graduate Admissions",
  recruitStudent_6: "Employment",
  recruitStudent_7: "Recruitment",
  recruitStudent_8: "Alumni Network",
  recruitStudent_9: "Education Development Foundation",
  recruitStudent_10:
    "President's Mailbox: president@bisu.edu.cn |Disciplinary Inspection and Supervision",
  recruitStudent_11:
    "Beijing International Studies University © 京ICP备：05067963号",
  recruitStudent_12:
    "Cultural Heritage Protection and Network Security Registration No.: 110402430076",
  recruitStudent_13: "Copyright 2001-2010 B.I.S.U. All rights reserved",
  newsInformation: "NEWS",
  newsInformation_1: "News and Information",
  newsInformation_2: "Special Topics",
  newsInformation_3: "Headline News",
  noData: "No data available",
  relatedReading: "Related Reading",
  relatedReading_1: "Related Articles",
  relatedReading_2: "Related Reading",
  month_1: "Month",
  day_1: "Day",
  year_1: "Year",
  source_1: "Source",
  source_2: "Text",
  source_3: "Photography",
  source_4: "Editor",
  source_5: "Leadership Inspection",
  source_6: "Information Disclosure",
  source_7: "Share",
  source_8: "Scan WeChat QR code to share",
  source_9: "In WeChat, tap 'Discover' and scan the QR code",
  source_10: "Scan the QR code to share this article on your Moments.",
  successive_leaders: "Successive Leaders",
  successive_leaders1: "Successive Party Secretaries",
  successive_leaders2: "Successive Presidents",
  leadershipName: "Zhāng Tiān'ēn",
  leadershipName1: "Luó Lín",
  leadershipName2: "Sūn Píng",
  leadershipName3: "Táng Kǎi",
  leadershipName4: "Léi Wén",
  leadershipName5: "Lǐ Xiānhuī",
  leadershipName6: "Hóng Fúěr",
  leadershipName7: "Lǐù Fèngkuí",
  leadershipName8: "Cháng Diànyuán",
  leadershipName9: "Duàn Jiànguó",
  leadershipName10: "Fèng Pèi",
  leadershipName11: "Lǐ Chāng",
  leadershipName12: "Wén Gémò Qījiān", // This refers to the Cultural Revolution period
  leadershipName13: "Táng Kǎi",
  leadershipName14: "Zhāng Dào yī",
  leadershipName15: "Hán Kèhuá",
  leadershipName16: "Lǐ Xiānhuī",
  leadershipName17: "Cháng DiànYuán",
  leadershipName18: "Dù Jiāng",
  leadershipName19: "Zhōu Liè",
  leadershipName20: "Cáo Wèidōng",
};

export default en;
